<template>
  <!-- class="fullScreen" -->
  <div :class="{ show: showEditDialog, fullScreen: distriBution }">
    <div style="height: 100%" v-loading="loading">
      <Crumbs v-if="distriBution">
        <el-breadcrumb-item slot="after"> 商务绩效核算详情 </el-breadcrumb-item>
      </Crumbs>
      <div :class="{ fullScreenMain: distriBution }">
        <div :class="{ fullScreenMainCon: distriBution }">
          <template v-if="businessInformation">
            <el-descriptions style="margin-top: 10px" direction="vertical" :column="5" border>
              <template slot="title">
                <div class="info-title-with-button">
                  <div style="font-size: 16px; color: #000; font-weight: 700; margin-bottom: 5px">
                    商务基础信息
                  </div>
                  <el-button type="success" size="small">
                    <a
                      target="_blank"
                      href=" https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/项目商务绩效分配方法.pdf"
                      >绩效分配规则说明</a
                    ></el-button
                  >
                </div>
              </template>
              <el-descriptions-item label="商务名称" :span="2">
                {{ businessInformation.businessName || '' }}
              </el-descriptions-item>
              <el-descriptions-item label="商务类型" :span="1">
                {{ businessInformation.businessType | dict(dictData.businessType) }}
              </el-descriptions-item>
              <el-descriptions-item label="项目来源" :span="1">
                {{ businessInformation.projectSource | dict(dictData.projectSource) }}
              </el-descriptions-item>
              <el-descriptions-item label="业务种类" :span="1">
                {{ businessInformation.businessLines | dict(dictData.businessLines) }}
              </el-descriptions-item>

              <el-descriptions-item label="业主单位" :span="3">
                {{ businessInformation.ownerUnit || '' }}
              </el-descriptions-item>
              <el-descriptions-item label="项目承接公司" :span="1">
                {{ businessInformation.companyType | dict(dictData.companyType) }}
              </el-descriptions-item>
              <el-descriptions-item label="实际商务负责人" :span="1">
                {{ businessInformation.principalUserName | addDept(CompanyMembers) }}
              </el-descriptions-item>
            </el-descriptions>
          </template>

          <el-descriptions
            style="margin-top: 10px; margin-bottom: 10px"
            title="商务资金结算"
            direction="vertical"
            :column="5"
            border
          >
            <template slot="extra">
              <span style="font-size: 12px; color: gray">单位：元</span>
            </template>
            <el-descriptions-item
              label="我方合同金额"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              {{ businessInformation.ourContractAmount || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                税费总计
                <span style="font-size: 12px; color: red; padding-left: 20px">
                  包含增值税、附加税、印花税
                </span>
              </template>
              {{ businessInformation.taxesFee || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              label="招投标费用"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              {{ businessInformation.tenderFee || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              label="预计外部协作资金"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              {{ businessInformation.exteriorBusinessCosts || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              label="实际外部协作资金"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              {{ businessInformation.actualExteriorBusinessCosts || 0 }}
            </el-descriptions-item>
            <el-descriptions-item :span="2">
              <template slot="label">
                预计可支配金额
                <span style="font-size: 12px; color: red; padding-left: 20px">
                  预计可支配金额 = 我方合同金额 - 税费 - 招投标费用 - 预计外部协作资金
                </span>
              </template>
              {{ businessInformation.predictDisposableProjectExpense || 0 }}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label"> 到款金额 </template>
              {{ businessInformation.receivedAmount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label">
                <div>
                  内部生产资金
                  <el-button
                    style="margin-left: 20px"
                    type="primary"
                    size="mini"
                    @click="showNBSCFY"
                    :loading="isNBSCFYButtonLoading"
                    >查看比例</el-button
                  >
                </div>
                <div style="margin-top: 5px">
                  <span style="font-size: 12px; color: red">
                    内部生产资金 = 预计可支配金额 X 生产资金比例
                  </span>
                </div>
              </template>
              {{ businessInformation.productionCost || 0 }}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label">
                <div>
                  外协生产资金
                  <el-button
                    style="margin-left: 20px"
                    type="primary"
                    size="mini"
                    @click="showWXSCFY"
                    :loading="isWXSCFYButtonLoading"
                    >查看详情</el-button
                  >
                </div>
                <div style="margin-top: 5px; opacity: 0%">
                  <span style="font-size: 12px; color: red"> 外协生产资金 </span>
                </div></template
              >
              {{ businessInformation.projectOutsourceByBusiness || 0 }}
            </el-descriptions-item>
            <el-descriptions-item :span="3">
              <template slot="label">
                结算可支配资金
                <span style="font-size: 12px; color: red; padding-left: 20px">
                  结算可支配资金 = （预计可支配金额 - 内部生产资金 - 外协生产资金）X 25%
                </span>
              </template>
              {{ businessInformation.interiorBusinessPerformance || 0 }}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label">
                <div>商务部管理资金</div>
                <div style="margin-top: 5px">
                  <span style="font-size: 12px; color: red">
                    商务部管理资金 = 结算可支配资金 X 20%
                  </span>
                </div>
              </template>
              {{ businessInformation.businessDeptPerformance || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              label-class-name="projectBGC"
              content-class-name="projectBGC"
              :span="1"
            >
              <template slot="label"> 实际绩效金额 </template>
              {{ businessInformation.actualPerformance || 0 }}
            </el-descriptions-item>
          </el-descriptions>

          <div class="title-with-button">
            <div style="font-size: 16px; color: #000; font-weight: 700; margin-bottom: 5px">
              绩效核算
            </div>
            <el-button
              type="primary"
              size="small"
              style="margin: 0px 10px 0px 40px"
              @click="showCostDialog"
              :loading="isCostDialogLoading"
              >查看支出信息</el-button
            >
            <el-button
              type="primary"
              size="small"
              plain
              @click="showProportionDialog"
              :loading="isProportionDialogLoading"
              >查看原绩效比例分配</el-button
            >
          </div>

          <div style="min-height: 400px">
            <el-table
              :data="tableData[Index]"
              border
              style="width: 100%; margin-bottom: 25px"
              :key="ratioKey"
            >
              <!-- <el-table-column align="center" type="index" label="序号" width="40"> </el-table-column> -->
              <el-table-column
                align="center"
                prop="workContent"
                label="工作内容"
                width="140"
                :show-overflow-tooltip="false"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="participatingDistributor"
                label="参与角色"
                width="140"
                :show-overflow-tooltip="false"
              >
              </el-table-column>

              <el-table-column
                align="center"
                label="绩效占比"
                min-width="120"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  <div>
                    <span
                      v-if="
                        scope.row.nowRatio ||
                        scope.row.nowRatio == 0 ||
                        scope.row.nowMoney ||
                        scope.row.nowMoney == 0
                      "
                      >原：</span
                    >{{
                      scope.row.contentType == 'MONEY_TYPE'
                        ? scope.row.money
                          ? scope.row.money + '元'
                          : 0 + '元'
                        : scope.row.ratio
                        ? scope.row.ratio + '%'
                        : 0 + '%'
                    }}
                  </div>
                  <div
                    style="color: red"
                    v-if="
                      scope.row.nowRatio ||
                      scope.row.nowRatio == 0 ||
                      scope.row.nowMoney ||
                      scope.row.nowMoney == 0
                    "
                  >
                    <span>现：</span
                    >{{
                      scope.row.contentType == 'MONEY_TYPE'
                        ? scope.row.nowMoney
                          ? scope.row.nowMoney + '元'
                          : 0 + '元'
                        : scope.row.nowRatio
                        ? scope.row.nowRatio + '%'
                        : 0 + '%'
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                label="绩效总额"
                min-width="120"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  <div>
                    <span
                      v-if="
                        scope.row.nowPerformanceTotalMoney ||
                        scope.row.nowPerformanceTotalMoney == 0
                      "
                      >原：</span
                    >
                    {{ scope.row.performanceTotalMoneyFormat || 0 }}元
                  </div>
                  <div
                    style="color: red"
                    v-if="
                      scope.row.nowPerformanceTotalMoney || scope.row.nowPerformanceTotalMoney == 0
                    "
                  >
                    <span>现：</span> {{ scope.row.nowPerformanceTotalMoneyFormat }}元
                  </div>
                </template>
              </el-table-column>

              <!-- <el-table-column align="center" label="事项说明" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <el-input
                  disabled
                  v-model="scope.row.instructionMatters"
                  type="textarea"
                  :autosize="{ minRows: 2 }"></el-input>
              </template>
            </el-table-column> -->

              <el-table-column
                align="center"
                label="人员绩效明细"
                min-width="480"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  <div>
                    <template v-for="(i, j) in scope.row.userAssignment">
                      <template v-if="i.ruleCode == scope.row.ruleCode">
                        <div :key="j">
                          <div class="userProportion">
                            <template v-if="i.businessManagerSign === 'HAVE'"
                              ><span
                                >经理:<el-tag
                                  type="warning"
                                  style="width: 85px; margin-left: 5px"
                                  >{{ i.userName }}</el-tag
                                ></span
                              >
                              <div class="content">
                                <div class="ratio">
                                  <!-- 原占比/原金额 -->
                                  <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                    原占比: {{ i.userRatio || '0' }}%
                                  </div>
                                  <div v-else>原金额: {{ i.userMoney || 0 }}元</div>
                                  <!-- 当前占比/当前金额 -->
                                  <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                    当前占比:
                                    <span style="color: red">{{ i.nowRatio || '0' }}%</span>
                                  </div>
                                  <div v-else>
                                    当前金额:
                                    <span style="color: red">{{ i.nowMoney || 0 }}元</span>
                                  </div>
                                </div>
                                <div>
                                  绩效奖金:<el-input
                                    :disabled="disabled || isDetail == 1"
                                    style="width: 90px; margin-left: 5px"
                                    v-model="i.performanceValue"
                                    @input="$forceUpdate()"
                                    @change="onPerformanceChange(scope.row, i)"
                                  ></el-input
                                  >元
                                </div>
                              </div>
                            </template>

                            <template v-else>
                              <template v-if="i.userType === 'MEMBER'">
                                <span
                                  >成员:<el-tag style="width: 85px; margin-left: 5px">{{
                                    i.userName
                                  }}</el-tag></span
                                >
                                <div class="content">
                                  <div class="ratio">
                                    <!-- 原占比/原金额 -->
                                    <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                      原占比: {{ i.userRatio || '0' }}%
                                    </div>
                                    <div v-else>原金额: {{ i.userMoney || 0 }}元</div>
                                    <!-- 当前占比/当前金额 -->
                                    <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                      当前占比:
                                      <span style="color: red">{{ i.nowRatio || '0' }}%</span>
                                    </div>
                                    <div v-else>
                                      当前金额:
                                      <span style="color: red">{{ i.nowMoney || 0 }}元</span>
                                    </div>
                                  </div>
                                  <div>
                                    绩效奖金:<el-input
                                      :disabled="disabled || isDetail == 1"
                                      style="width: 90px; margin-left: 5px"
                                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                      v-model="i.performanceValue"
                                      @input="$forceUpdate()"
                                      @change="onPerformanceChange(scope.row, i)"
                                    ></el-input
                                    >元
                                  </div>
                                </div>
                              </template>
                              <template v-if="i.userType === 'DEPT'">
                                <span
                                  >部门:<el-tag
                                    type="success"
                                    style="width: 85px; margin-left: 5px"
                                    >{{ i.deptName }}</el-tag
                                  ></span
                                >
                                <div class="content">
                                  <div class="ratio">
                                    <!-- 原占比/原金额 -->
                                    <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                      原占比: {{ i.userRatio || '0' }}%
                                    </div>
                                    <div v-else>原金额: {{ i.userMoney || 0 }}元</div>
                                    <!-- 当前占比/当前金额 -->
                                    <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                      当前占比:
                                      <span style="color: red">{{ i.nowRatio || '0' }}%</span>
                                    </div>
                                    <div v-else>
                                      当前金额:
                                      <span style="color: red">{{ i.nowMoney || 0 }}元</span>
                                    </div>
                                  </div>
                                  <div>
                                    绩效奖金:<el-input
                                      :disabled="disabled || isDetail == 1"
                                      style="width: 90px; margin-left: 5px"
                                      onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                      v-model="i.performanceValue"
                                      @input="$forceUpdate()"
                                      @change="onPerformanceChange(scope.row, i)"
                                    ></el-input
                                    >元
                                  </div>
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                    </template>
                    <div>
                      <template v-if="scope.row.ruleCode === 'LEAD_COMMUNICATION'">
                        <el-alert
                          ref="alert_Ref"
                          v-if="passOutShow"
                          :title="'注意: 当前绩效已超出：' + outMoney + '元'"
                          type="error"
                          center
                          show-icon
                          :closable="false"
                        >
                        </el-alert>
                        <el-alert
                          ref="alert_Ref"
                          v-else-if="belowShow"
                          :title="'注意:当前可分配绩效总额剩余：' + outMoney + '元'"
                          type="warning"
                          center
                          show-icon
                          :closable="false"
                        >
                        </el-alert>
                      </template>
                      <template v-if="scope.row.ruleCode === 'OTHER'">
                        <el-alert
                          ref="companyTipsRef"
                          title="注意：当前公司绩效总金额应减去信息提供人绩效总金额"
                          v-if="isCompanyTipsShow"
                          type="warning"
                          center
                          show-icon
                          :closable="false"
                        >
                        </el-alert>
                      </template>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- <OpinionArea :id="ID"></OpinionArea> -->
        </div>
        <div class="fullScreenOperation" v-if="distriBution">
          <el-button v-if="isDetail != 1" type="success" class="teal_bg" @click="addFn()"
            >保存</el-button
          >
          <el-button class="return" type="info" @click="returnFn">返回</el-button>
        </div>
      </div>
    </div>

    <!-- 查看内部生产资金比例 -->
    <EditDialog width="60%" :isShow.sync="NBSCFY_ratio_show">
      <div slot="title" class="title">内部生产资金比例</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          当前生产资金比例 ：
          <span style="color: red">{{ NBSCFY_ratio_data.productionCostSumRatio * 100 }}%</span>
        </div>
        <div class="below-content">
          <div style="width: 50%">
            <el-table
              :data="NBSCFY_ratio_data.productionCostRatioLogByProjectList"
              style="width: 100%"
              border
              row-key="projectId"
            >
              <el-table-column type="expand" width="30">
                <template slot-scope="props">
                  <div
                    style="
                      font-size: 14px;
                      color: black;
                      font-weight: 700;
                      margin-bottom: 5px;
                      margin-left: 20px;
                      margin-top: 10px;
                    "
                  >
                    历史记录：
                  </div>
                  <el-table
                    :data="props.row.productionCostRatioLogList"
                    border
                    style="width: 92%; margin-bottom: 10px; margin-left: 20px"
                  >
                    <el-table-column
                      type="index"
                      align="center"
                      width="30"
                      :show-overflow-tooltip="false"
                    ></el-table-column>
                    <el-table-column
                      label="业务种类"
                      prop="businessLines"
                      align="center"
                      width="105"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.businessLines | dict(dictData.businessLines) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="项目来源"
                      prop="projectSource"
                      align="center"
                      width="85"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.projectSource | dict(dictData.projectSource) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="productionCostRatio"
                      label="比例"
                      width="70"
                      align="center"
                      :show-overflow-tooltip="false"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.productionCostRatio * 100 }}%
                      </template>
                    </el-table-column>
                    <el-table-column prop="createdDate" label="更改日期" align="center">
                      <template slot-scope="scope">
                        {{ scope.row.createdDate | dateFormat }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <!-- <el-table-column label="序号" prop="id" align="center" width="40"> </el-table-column> -->
              <el-table-column
                label="项目名称"
                prop="projectName"
                align="center"
                :show-overflow-tooltip="false"
              >
              </el-table-column>
              <el-table-column
                label="生产费用占比"
                prop="productionCostRatioByProject"
                align="center"
                width="80"
                class-name="height-line"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  {{ scope.row.productionCostRatioByProject * 100 }}%
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="padding: 0 0 0 5px; flex: 1">
            <img src="@/assets/简化生产费用比例.png" alt="生产资金比例说明" width="96%" />
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="NBSCFY_ratio_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 查看项目外协费用 -->
    <EditDialog width="60%" :isShow.sync="WXSCFY_detail_show">
      <div slot="title" class="title">外协生产资金</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          目前外协生产总资金 ：
          <span style="color: red">{{ WXSCFY_detail_data.outsourceSumCost }}元</span>
        </div>
        <el-table
          :data="WXSCFY_detail_data.projectOutsourceLogByProjectList"
          style="width: 100%"
          border
        >
          <el-table-column type="expand" width="30">
            <template slot-scope="props">
              <div
                style="
                  font-size: 14px;
                  color: black;
                  font-weight: 700;
                  margin-bottom: 5px;
                  margin-left: 20px;
                  margin-top: 10px;
                "
              >
                外协单位信息：
              </div>
              <el-table
                :data="props.row.projectOutsourceLogByName"
                border
                style="width: 96%; margin-bottom: 10px; margin-left: 20px"
              >
                <el-table-column type="expand" width="30">
                  <template slot-scope="props">
                    <div
                      style="
                        font-size: 14px;
                        color: black;
                        font-weight: 700;
                        margin-bottom: 5px;
                        margin-left: 20px;
                        margin-top: 10px;
                      "
                    >
                      历史记录：
                    </div>
                    <el-table
                      :data="props.row.projectOutsourceLogRespList"
                      border
                      style="width: 96%; margin-bottom: 10px; margin-left: 20px"
                    >
                      <el-table-column
                        label="序号"
                        type="index"
                        align="center"
                        width="100"
                        :show-overflow-tooltip="false"
                      ></el-table-column>
                      <el-table-column prop="cost" label="外协生产资金" align="center" width="180">
                        <template slot-scope="scope"> {{ scope.row.outsourceCost }}元 </template>
                      </el-table-column>
                      <el-table-column prop="date" label="更改日期" align="center">
                        <template slot-scope="scope">
                          {{ scope.row.createdDate | dateFormat }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="outsourceName" label="外协单位" align="center">
                </el-table-column>
                <el-table-column
                  prop="outsourceSumCostByName"
                  label="外协生产资金"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope"> {{ scope.row.outsourceSumCostByName }}元 </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <!-- <el-table-column label="序号" prop="id" align="center" width="100"> </el-table-column> -->
          <el-table-column label="项目名称" prop="projectName" align="center"> </el-table-column>
          <el-table-column label="业务种类" prop="businessLines" align="center" width="170">
            <template slot-scope="scope">
              {{ scope.row.businessLines | dict(dictData.businessLines) }}
            </template>
          </el-table-column>
          <el-table-column label="项目来源" prop="projectSource" align="center" width="170">
            <template slot-scope="scope">
              {{ scope.row.projectSource | dict(dictData.projectSource) }}
            </template>
          </el-table-column>
          <el-table-column
            label="项目外协生产资金合计"
            prop="outsourceSumCostByProject"
            align="center"
            width="170"
            class-name="height-line"
          >
            <template slot-scope="scope"> {{ scope.row.outsourceSumCostByProject }}元 </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="WXSCFY_detail_show = false">返回</el-button>
      </div>
    </EditDialog>

    <EditDialog width="90%" :isShow.sync="costDialog_show">
      <div slot="title" class="title">
        <el-row>
          <el-col :span="12"><div>商务记账信息</div></el-col>
          <el-col :span="12"> <div>员工记账明细</div></el-col>
        </el-row>
      </div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-row>
          <el-col :span="12">
            <el-card>
              <div class="cost-dialog-title">
                <el-descriptions border size="medium">
                  <el-descriptions-item label="商务名称" labelStyle="min-width: 90px;">{{
                    businessInformation.businessName
                  }}</el-descriptions-item>
                  <el-descriptions-item
                    label="商务负责人"
                    labelStyle="min-width: 100px;"
                    contentStyle="min-width: 90px;"
                    >{{ businessInformation.principalUserName }}</el-descriptions-item
                  >
                </el-descriptions>
              </div>
              <el-table
                :data="costDialog_data.leftTableData"
                border
                ref="leftTableRef"
                height="350"
                style="width: 100%"
                v-loading="leftCostLoading"
                :highlight-current-row="false"
                :cell-class-name="setCellClass"
              >
                <el-table-column
                  type="index"
                  align="center"
                  label="序号"
                  width="70"
                  :show-overflow-tooltip="false"
                >
                </el-table-column>
                <el-table-column prop="targetName" width="100" align="center" label="姓名">
                </el-table-column>
                <el-table-column prop="role" width="150" align="center" label="角色">
                  <template slot-scope="scope">
                    {{ scope.row.role | dict(dictData.businessStaffRole) }}
                  </template>
                </el-table-column>
                <el-table-column prop="sumCost" align="center" label="总报销金额">
                </el-table-column>
                <el-table-column prop="sumTallyCost" align="center" label="总记账金额">
                </el-table-column>
                <el-table-column width="100" align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="selectUserCost(scope.row, scope.$index)"
                      >明细</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div style="text-align: right; margin-top: 10px">
                <el-pagination
                  @size-change="handleLeftSizeChange"
                  @current-change="handleLeftCurrentChange"
                  :current-page="leftParams.pageNow"
                  :page-sizes="[5, 10, 50, 100]"
                  :page-size="10"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="leftParams.total"
                >
                </el-pagination>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card v-loading="rightCostLoading">
              <div v-if="costDialog_data.selectId">
                <div class="cost-dialog-title">
                  <el-descriptions border :column="2" size="medium">
                    <el-descriptions-item
                      label="成员"
                      :span="1"
                      :content-class-name="[this.highlightedCell ? 'highlight' : '']"
                    >
                      {{ costDialog_data.selectName }}</el-descriptions-item
                    >
                    <el-descriptions-item label="角色" :span="1">
                      {{
                        costDialog_data.selectRole | dict(dictData.businessStaffRole)
                      }}</el-descriptions-item
                    >
                  </el-descriptions>
                </div>
                <el-table
                  :data="costDialog_data.rightTableData"
                  border
                  height="350"
                  style="width: 100%"
                >
                  <el-table-column
                    type="index"
                    align="center"
                    label="序号"
                    width="70"
                    :show-overflow-tooltip="false"
                  >
                  </el-table-column>
                  <el-table-column prop="date" align="center" label="记账时间">
                    <template slot-scope="scope">
                      {{ scope.row.createdDate | dateFormat }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="moduleType"
                    min-width="120"
                    align="center"
                    label="记账类型"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.moduleType | dict(dictData.moduleType) }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="sumCost" align="center" label="报销金额">
                  </el-table-column>
                  <el-table-column prop="costTally" align="center" label="记账金额">
                  </el-table-column>
                  <el-table-column
                    prop="staffBusinessPerformance"
                    width="120"
                    align="center"
                    label="操作"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="showCostDetail(scope.row)"
                        :loading="rightDetailLoading"
                        >详情</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 10px">
                  <el-pagination
                    @size-change="handleRightSizeChange"
                    @current-change="handleRightCurrentChange"
                    :current-page="rightParams.pageNow"
                    :page-sizes="[5, 10, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="rightParams.total"
                  >
                  </el-pagination>
                </div>
              </div>
              <div class="empty-detail" v-else><span class="content">点击左侧明细</span></div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="closeDialog">返回</el-button>
      </div>
    </EditDialog>

    <el-dialog
      :visible.sync="proportionDialog_show"
      width="80%"
      append-to-body
      center
      :before-close="handleCloseProportionDialog"
    >
      <div style="overflow: auto; height: 580px" name="content">
        <DistriBution
          :Disabled="true"
          :isModule="true"
          :distriBution="false"
          :options="{ businessManageId: ID }"
        ></DistriBution>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 120px" plain @click="handleCloseProportionDialog"
          >返回</el-button
        >
      </div>
    </el-dialog>

    <BusinessPerformanceDetailDialog
      v-if="costDetailDialog_show"
      :costDetailDialog_show="costDetailDialog_show"
      :costDetail="costDialog_data.costDetail"
      :type="costDialog_data.moduleType"
      :imgList="imgList"
      @closeDialog="handleCloseCostDetail"
    ></BusinessPerformanceDetailDialog>
  </div>
</template>

<script>
import { numberToCurrencyNo, delcommafy, changeMoneyToChinese } from '@/util/jsencrypt'
import { Message } from 'element-ui'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    BusinessPerformanceDetailDialog: () =>
      import('@/components/BusinessPerformanceDetailDialog.vue'),
    DistriBution: () => import('./DistriBution.vue'),
  },

  props: {
    isDetail: {
      type: Number,
      default: 0,
    },
    showEditDialog: {
      type: Boolean,
      default: true,
    },
    ID: {
      type: [Number, String],
      default: '',
    },
    isManage: {
      //是否商务填报进来
      type: Boolean,
      default: false,
    },
    distriBution: {
      type: Boolean,
      default: false,
    },
    Disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      businessInformation: state => state.business.businessInformation,
      userAssignmentMap: state => state.business.userAssignmentMap,
      CompanyMembers: state => state.business.CompanyMembers,
    }),
    // iSnum2() {
    //   let arr = this.tableData[this.Index]
    //   let all = 0
    //   if (arr && arr.length > 0) {
    //     arr.forEach((v, i) => {
    //       if ('LEAD_COMMUNICATION' === v.ruleCode) {
    //         if (v.userAssignment && v.userAssignment.length > 0) {
    //           v.userAssignment.forEach((k, j) => {
    //             if (k.performanceValue) {
    //               if ('LEAD_COMMUNICATION' === v.ruleCode) {
    //                 all += Number(k.performanceValue)
    //               }
    //             }
    //           })
    //         }
    //       }
    //     })
    //   }
    //   all = all.toFixed(2)
    //   return all
    // },

    // 报销单金额合计
    amount() {
      let sum = 0
      if (this.costDialog_data.costDetail.detailList) {
        sum = this.costDialog_data.costDetail.detailList.reduce(
          (total, item) => (total += Number(item.cost)),
          0
        )
      }
      return sum
    },
  },
  data() {
    return {
      value: '',
      Index: 0,
      AchievementInfo: {},
      tableData: [
        [
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '负责关键沟通与组织',
            ratioRule: '90%减以上3、4、5项',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '提供有效项目信息',
            ratioRule: '100元或1%(低于200元按200元)',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '商务跟进',
            ratioRule: '0%~20%',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '有效引荐',
            ratioRule: '0%~30%',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'REFERRER',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '售前和投标技术方案编制',
            // workContent: '投标文件技术方案编制',
            ratioRule: '0%~40%',
            participatingDistributor: '售前和投标技术方案编制部门',
            // participatingDistributor: '投标技术方案编制部门',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
            // ruleCode: 'BIDDING_SCHEME',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '其他',
            participatingDistributor: '公司',
            instructionMatters: '',
            ratio: 9,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRule: '剩余比例',
          },
        ],
        [
          {
            businessType: '有事前沟通新客户项目',
            workContent: '负责关键沟通与组织',
            ratioRule: '97%减以上3、4、5项',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '提供有效项目信息',
            ratioRule: '1%~3%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '商务跟进',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
            ratioRule: '0%~20%',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '有效引荐',
            ratioRule: '0%~30%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'REFERRER',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '售前和投标技术方案编制',
            ratioRule: '0%~20%',
            ratio: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            money: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '售前和投标技术方案编制部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '其他',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '公司',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRule: '剩余比例',
          },
        ],
        [
          {
            businessType: '有事前沟通老客户项目',
            workContent: '负责关键沟通与组织',
            ratioRule: '97%减3、4、5、6项',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '提供有效项目信息',
            ratioRule: ' 1%~3%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '商务跟进',
            ratioRule: '0%~20%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '有效引荐（历史商务关系）',
            ratioRule: '0%~30%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'HISTORICAL_BUSINESS_REFERRER',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '售前和投标技术方案编制',
            ratioRule: '0%~20%',
            ratio: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            money: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '售前和投标技术方案编制部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '驻场或原项目实施',
            ratioRule: '0%~20%',
            ratio: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            money: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '驻场团队或原实施团队所在部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROJECT_IMPLEMENTATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '其他',
            ratioRule: '剩余比例',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '公司',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRuleDetail: '',
          },
        ],
      ],
      ratio: '',
      newRatioL: '',
      opinionPerformanceRecordList: [],
      businessManage: [],
      dialogFormVisible: false,
      loading: false,
      disabled: this.Disabled || false,
      dictData: {
        moduleType: [],
        businessType: [],
        businessDisbursenentType: [],
        projectSource: [],
        companyType: [],
        businessLines: [],
        businessStaffRole: [],
      },

      NBSCFY_ratio_show: false, // 内部生产资金弹框显示隐藏
      WXSCFY_detail_show: false, //外协生产资金详情弹框显示隐藏
      costDialog_show: false, //报销信息弹框显示隐藏
      costDetailDialog_show: false,

      isNBSCFYButtonLoading: false,
      isWXSCFYButtonLoading: false,
      isCostDialogLoading: false, //报销详情button按钮的loading
      leftCostLoading: false, //弹框左侧表格loading
      rightCostLoading: false, //弹框右侧表格loading
      rightDetailLoading: false,

      leftParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        businessManageId: '',
      }, //报销信息左侧弹框的请求参数
      rightParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        businessManageId: '',
        targetId: '',
      }, //员工报销详情右侧弹框请求参数

      NBSCFY_ratio_data: {},
      WXSCFY_detail_data: {},
      costDialog_data: {
        moduleType: '',
        selectName: '', //点击左侧明细后右侧显示姓名
        selectRole: '', //点击左侧明细后右侧显示角色
        selectId: '', //点击左侧明细后右侧显示id
        // 左侧商务报销信息（按员工划分
        leftTableData: [],
        rightTableData: [], //右侧员工明细
        costDetail: {},
      },
      oldRegistrationInfo: {}, //原住处登记单数据
      registrationIsShow: false, //支出登记单展示对话框

      img_index: 0, //附件图片预览下标
      imgList: [], //附件图片数组
      dialogVisible: false, //图片预览
      filepath: process.env.VUE_APP_FILEPATH,

      instInvolved: {}, //当前进度处理人信息
      highlightedCell: '', //当前选中行

      isCompanyTipsShow: false, // 公司绩效提示显示：当信息提供人绩效为金额类型时
      ratioKey: 0,

      passOutShow: false,
      belowShow: false,
      outMoney: 0,
      isLeadMoneyChange: false,
      isProportionDialogLoading: false,
      proportionDialog_show: false,

      //标识当前核算单是否保存过,空表示没保存过，0表示保存过
      calculatePerformanceStatus: null,
      actualPerformance: 0,
    }
  },
  created() {
    this.passOutShow = false
    this.belowShow = false
    this.outMoney = 0
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('BUSINESS_LINES', true)
      .then(res => {
        this.dictData.businessLines = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('BUSINESS_MODULE_TYPE', true)
      .then(res => {
        this.dictData.moduleType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('BUSINESS_STAFF_ROLE', true)
      .then(res => {
        this.dictData.businessStaffRole = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    // 添加部门前缀
    addDept(val, n) {
      let name = ''
      if (n && n.length > 0) {
        for (let index = 0; index < n.length; index++) {
          const v = n[index]
          if (v.userName == val) {
            var newArr = v.departmentName.split(',')
            name = v.userName + '-' + newArr[0]
            break
          }
        }
      } else {
        name = val
      }
      return name
    },

    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },

    amountToConvert(n) {
      if (!n) return
      n = Number(n).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return n
    },
  },
  watch: {
    ID: {
      immediate: true,
      handler(val) {
        this.getData()
      },
    },
    'businessManage.performanceStatus': {
      immediate: true,
      handler(val) {
        if (val != 0 && val) {
          this.disabled = true
        }
      },
    },

    // 监听额度金额变化
    outMoney: {
      handler(newVal) {
        if (Number(delcommafy(newVal)) >= 0.01) {
          this.isLeadMoneyChange = true
        } else {
          this.isLeadMoneyChange = false
          this.passOutShow = false
          this.belowShow = false
        }
      },
    },
  },
  methods: {
    async addFn() {
      let obj = this.$refs
      if (obj.companyTipsRef) {
        delete obj.companyTipsRef
      }
      for (const key in obj) {
        if (obj.hasOwnProperty.call(obj, key)) {
          const element = obj[key]
          if (element) {
            return this.$message({
              message: '请核对绩效金额是否分配正确！',
              type: 'error',
            })
          }
        }
      }
      this.AchievementInfo = {}
      let userList = []
      let businessPerformanceList = []
      let arr = this.tableData[this.Index]
      this.AchievementInfo.businessManageId = this.businessManage.id
      if (arr && arr.length > 0) {
        let arr2 = [...arr]
        businessPerformanceList = arr2.map(item => {
          return {
            id: item.id,
            nowRatio: item.nowRatio, //当前行比例
            nowMoney: item.nowMoney, //当前行金额
            nowPerformanceValue: item.nowPerformanceTotalMoney
              ? item.nowPerformanceTotalMoney
              : item.performanceTotalMoney, //当前行绩效总额
          }
        })
        arr.forEach(item => {
          if (item.userAssignment && item.userAssignment.length > 0) {
            let user = item.userAssignment.map(userItem => {
              return {
                businessId: this.businessManage.id,
                id: userItem.id,
                performanceValue: userItem.performanceValue,
                nowUserRatio: userItem.nowRatio,
                nowUserMoney: userItem.nowMoney,
                ruleCode: userItem.ruleCode,
                staffId: userItem.staffId,
              }
            })
            userList = [...userList, ...user]
          }
        })
      }
      this.AchievementInfo.businessPerformanceList = businessPerformanceList
      this.AchievementInfo.userAssignmentMap = userList
      const res = await this.$api.businessPerformance.saveOrUpdateCalculatePerformance(
        this.AchievementInfo
      )
      this.returnFn(1)
    },
    async getData() {
      if (this.ID) {
        this.loading = true
        this.isCompanyTipsShow = false
        this.opinionPerformanceRecordList = []
        const res = await this.$api.businessPerformance.getCalculatePerformanceInfo(this.ID)

        if (res.data.businessManage) {
          // 处理我方合同金额
          res.data.businessManage.ourContractAmount = numberToCurrencyNo(
            res.data.businessManage.ourContractAmount
          )
          // 处理税费计算总税费
          res.data.businessManage.taxesFee = numberToCurrencyNo(res.data.businessManage.taxesFee)
          //处理投标费用
          res.data.businessManage.tenderFee = numberToCurrencyNo(res.data.businessManage.tenderFee)
          // 处理外协费用
          res.data.businessManage.exteriorBusinessCosts = this.getContentCooperationMoney(
            res.data.businessManage.ourContractAmount,
            res.data.businessManage.contentCooperationType,
            res.data.businessManage.contentCooperationMoney,
            res.data.businessManage.contentCooperationRatio
          )
          // 处理预计可支配金额
          res.data.businessManage.predictDisposableProjectExpense = numberToCurrencyNo(
            res.data.businessManage.predictDisposableProjectExpense
          )
          // 处理内部生产资金
          res.data.businessManage.productionCost = numberToCurrencyNo(
            res.data.businessManage.productionCost
          )
          // 处理外协生产资金
          res.data.businessManage.projectOutsourceByBusiness = numberToCurrencyNo(
            res.data.businessManage.projectOutsourceByBusiness
          )
          res.data.businessManage.interiorBusinessPerformance = numberToCurrencyNo(
            res.data.businessManage.interiorBusinessPerformance
          )
          res.data.businessManage.businessDeptPerformance = numberToCurrencyNo(
            res.data.businessManage.businessDeptPerformance
          )
          res.data.businessManage.actualPerformance = numberToCurrencyNo(
            res.data.businessManage.actualPerformance
          )

          if (res.data.businessManage.businessType === 'WSQGTJBLXM') {
            this.value = '无事前沟通竞标类项目'
            this.Index = 0
          } else if (res.data.businessManage.businessType === 'YSQGTXKHXM') {
            this.value = '有事前沟通新客户项目'
            this.Index = 1
          } else if (res.data.businessManage.businessType === 'YSQGTLKHXM') {
            this.value = '有事前沟通老客户项目'
            this.Index = 2
          }
          this.$store.commit({
            type: 'business/SET_BUSINESS_INFORMATION',
            businessInformation: res.data.businessManage,
          })
          this.$store.commit({
            type: 'business/SET_USER_ASSIGNMENT_MAP',
            userAssignmentMap: res.data.userAssignmentMap,
          })
          this.businessManage = res.data.businessManage
        }
        // if (res.data.opinionPerformanceRecordList.length > 0) {
        //   this.opinionPerformanceRecordList = res.data.opinionPerformanceRecordList
        // }
        // 是编制部门并且是金额 先从总绩效扣除
        const Consultbiddingscheme = res.data.businessPerformanceList.find(
          item => item.ruleCode === 'CONSULT_BIDDING_SCHEME' && item.contentType === 'MONEY_TYPE'
        )
        if (Consultbiddingscheme && Consultbiddingscheme.nowMoney > 0) {
          this.actualPerformance =
            Number(delcommafy(this.businessInformation.actualPerformance)) -
            Consultbiddingscheme.nowMoney
        } else {
          this.actualPerformance = Number(delcommafy(this.businessInformation.actualPerformance))
        }
        this.actualPerformance = Number(this.actualPerformance.toFixed(2))
        this.tableData[this.Index].forEach((v, i) => {
          // 工作内容绩效
          res.data.businessPerformanceList.forEach((k, j) => {
            if (v.ruleCode === k.ruleCode) {
              v.contentType = k.contentType
              v.id = k.id
              v.instructionMatters = k.instructionMatters
              if (res.data.businessManage.calculatePerformanceStatus === 0) {
                // 说明该商务绩效被保存过，直接调用服务器返回值
                v.ratio = k.nowRatio
                v.money = k.nowMoney
                v.performanceTotalMoney = k.nowPerformanceValue
                v.performanceTotalMoneyFormat = numberToCurrencyNo(v.performanceTotalMoney)
              } else {
                if (v.contentType == 'MONEY_TYPE') {
                  v.money = k.money
                  v.performanceTotalMoney = v.money
                  v.performanceTotalMoneyFormat = v.money
                } else {
                  v.ratio = k.ratio
                  v.performanceTotalMoney = v.ratio * this.actualPerformance * 0.01
                  if (v.performanceTotalMoney !== 0) {
                    v.performanceTotalMoney = v.performanceTotalMoney.toFixed(2)
                    v.performanceTotalMoneyFormat = numberToCurrencyNo(v.performanceTotalMoney)
                  }
                }
              }
            }
            this.ratioKey++
          })
        })
        // 人员绩效
        if (res.data.userAssignmentMap !== null) {
          this.tableData[this.Index].forEach((v, i) => {
            // 判断当前信息提供人的绩效类型时金额还是比例
            if (v.ruleCode == 'PROVIDE_INFORMATION') {
              if (v.contentType == 'MONEY_TYPE') {
                this.isCompanyTipsShow = true
              }
            }
            res.data.userAssignmentMap.forEach(k => {
              if (v.ruleCode === k.ruleCode) {
                v.userAssignment.push(k)
              }
            })
            v.userAssignment.forEach(userItem => {
              if (res.data.businessManage.calculatePerformanceStatus === 0) {
                // 该绩效信息保存过，直接用nowUserRatio
                userItem.performanceValue = userItem.performanceValue
                userItem.nowperformanceValue = userItem.performanceValue
                userItem.nowRatio = userItem.nowUserRatio
                userItem.userRatio = userItem.nowUserRatio
                userItem.nowMoney = userItem.nowUserMoney
                userItem.userMoney = userItem.nowUserMoney
              } else {
                // 未保存过用userRatio
                let perValue = 0 //计算当前员工绩效
                if (userItem.contentType == 'RATIO_TYPE') {
                  perValue = userItem.userRatio * this.actualPerformance * 0.01
                  if (perValue !== 0) {
                    perValue = perValue.toFixed(2)
                  }
                } else {
                  perValue = userItem.userMoney
                }
                userItem.performanceValue = perValue
                userItem.nowperformanceValue = perValue
                userItem.nowRatio = userItem.userRatio
                userItem.userRatio = userItem.userRatio
                userItem.nowMoney = userItem.userMoney
                userItem.userMoney = userItem.userMoney
                // 对isCompanyTipsShow做监听，如果信息提供人为分配金额，则当前公司绩效总金额就要减去信息提供人绩效金额
                const provide = this.tableData[this.Index].find(
                  item => item.ruleCode == 'PROVIDE_INFORMATION'
                )
                if (provide && provide.contentType == 'MONEY_TYPE') {
                  const other = this.tableData[this.Index].find(item => item.ruleCode == 'OTHER')
                  other.performanceTotalMoney = (
                    Number(other.performanceTotalMoney) - Number(provide.performanceTotalMoney)
                  ).toFixed(2)
                  other.performanceTotalMoneyFormat = numberToCurrencyNo(
                    other.performanceTotalMoney
                  )
                }
              }
            })
          })
        }
        this.loading = false
      }
    },
    // getTotalTaxation(ourContractAmount, vatRate) {
    //   // 预计增值税
    //   let tax1 =
    //     (((Number(delcommafy(ourContractAmount)) * 10000) / (1000000 + vatRate * 10000)) *
    //       (vatRate * 10000)) /
    //     10000
    //   tax1 = tax1.toFixed(2)
    //   //预计附加税
    //   let tax2 = tax1 ? (tax1 * 0.12).toFixed(2) : 0
    //   // 预计印花税
    //   let tax3 = (delcommafy(ourContractAmount) * 0.0003).toFixed(2)
    //   let total = Number(tax1) + Number(tax2) + Number(tax3)
    //   total = numberToCurrencyNo(total.toFixed(2))
    //   return total
    // },
    getContentCooperationMoney(
      ourContractAmount,
      contentCooperationType,
      contentCooperationMoney,
      contentCooperationRatio
    ) {
      if (contentCooperationType && ourContractAmount) {
        let money = 0
        switch (contentCooperationType) {
          case 1:
            money = Number(contentCooperationMoney)
            break
          case 2:
            money = Number(delcommafy(ourContractAmount)) * Number(contentCooperationRatio) * 0.01
            break
        }
        return numberToCurrencyNo(money)
      } else {
        return '0'
      }
    },
    max_change(row) {
      if (row.ratio < row.percentage[0]) {
        row.ratio = row.percentage[0]
        row.showtips = true
        setTimeout(function () {
          row.showtips = false
        }, 2000)
      } else if (row.ratio > row.percentage[1]) {
        row.ratio = row.percentage[1]
        row.showtips = true
        setTimeout(function () {
          row.showtips = false
        }, 2000)
      } else {
        row.showtips = false
      }
    },
    returnFn() {
      this.$emit('update:showEditDialog', false)
      this.$emit('updateData')
    },
    showNBSCFY() {
      this.isNBSCFYButtonLoading = true
      this.NBSCFY_ratio_data = {}
      this.$api.productionCostRatioLog
        .productionCostRatioLogByBusiness({ businessId: this.businessManage.id })
        .then(res => {
          this.isNBSCFYButtonLoading = false
          this.NBSCFY_ratio_data = res.data
          this.NBSCFY_ratio_show = true
        })
        .catch(err => {
          this.isNBSCFYButtonLoading = false
          console.log(err)
        })
    },
    showWXSCFY() {
      this.isWXSCFYButtonLoading = true
      this.WXSCFY_detail_data = {}
      this.$api.productionCostRatioLog
        .projectOutsourceLogByBusiness({ businessId: this.businessManage.id })
        .then(res => {
          this.isWXSCFYButtonLoading = false
          this.WXSCFY_detail_data = res.data
          this.WXSCFY_detail_show = true
        })
        .catch(err => {
          this.isWXSCFYButtonLoading = false
          console.log(err)
        })
    },

    // 获取左侧商务报销列表数据
    async getLeftData() {
      this.costDialog_data.leftTableData = []
      this.leftCostLoading = true
      this.leftParams.businessManageId = this.businessInformation.id
      const { data: res } = await this.$api.businessTally.listGroupBusinessOrStaff(this.leftParams)
      this.leftCostLoading = false
      if (res?.records) {
        this.leftParams.total = res.total
        this.costDialog_data.leftTableData = res.records
      }
    },

    //todo 获取右侧员工详情报销列表数据
    async getRightData() {
      this.rightCostLoading = true
      this.costDialog_data.rightTableData = []
      this.rightParams.businessManageId = this.businessInformation.id
      this.rightParams.targetId = this.costDialog_data.selectId
      const { data: res } = await this.$api.businessTally.listToStaff(this.rightParams)
      this.rightCostLoading = false
      if (res?.records) {
        this.rightParams.total = res.total
        this.costDialog_data.rightTableData = res.records
      }
    },

    handleCloseProportionDialog() {
      this.proportionDialog_show = false
    },
    showProportionDialog() {
      this.isProportionDialogLoading = true
      this.proportionDialog_show = true
      this.isProportionDialogLoading = false
    },

    showCostDialog() {
      this.costDialog_data = {
        selectName: '',
        selectRole: '',
        costDetail: {},
        leftTableData: [],
        rightTableData: [],
      }
      this.isCostDialogLoading = true
      this.getLeftData()
      this.costDialog_show = true
      this.isCostDialogLoading = false
    },

    selectUserCost(value, index) {
      this.rightCostLoading = true
      this.highlightedCell = { rowIndex: index }
      this.costDialog_data.selectId = value.targetId
      this.costDialog_data.selectName = value.targetName
      this.costDialog_data.selectRole = value.role
      this.getRightData()
    },

    // 左侧分页，页大小改变
    handleLeftSizeChange(val) {
      this.leftParams.pageSize = val
      this.leftParams.pageNow = 1
      this.getLeftData()
    },
    handleLeftCurrentChange(val) {
      this.leftParams.pageNow = val
      this.getLeftData()
    },

    handleRightSizeChange(val) {
      this.rightParams.pageSize = val
      this.rightParams.pageNow = 1
      this.getRightData()
    },
    handleRightCurrentChange(val) {
      this.rightParams.pageNow = val
      this.getRightData()
    },

    // 打开报销详情信息对话框
    async showCostDetail(val) {
      this.rightDetailLoading = true
      this.costDialog_data.costDetail = {}
      this.costDialog_data.moduleType = val.moduleType
      if (val.moduleType == 'BUSINESS_EXPENSE') {
        const { data: res } = await this.$api.businessExpense.selectDetailById(val.professionalId)
        this.rightDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      } else if (val.moduleType == 'BUSINESS_REGISTRATION_EXPENDITURE') {
        const { data: res } = await this.$api.register.selectDetailById(val.professionalId)
        this.rightDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      }
      this.costDialog_data.costDetail.detailList.forEach(item => {
        item.expenseFileList.forEach(v => {
          if (!this.isShowType(v)) {
            this.imgList.push(v)
          }
        })
      })
      this.costDetailDialog_show = true
    },

    handleCloseCostDetail() {
      this.costDetailDialog_show = false
    },
    // registrationIsShowFn() {
    //   if (this.costDialog_data.costDetail.registrationExpenditureId) {
    //     this.$api.register
    //       .selectDetailById(this.costDialog_data.costDetail.registrationExpenditureId)
    //       .then(res => {
    //         if (res.data) {
    //           this.oldRegistrationInfo = res.data
    //           this.registrationIsShow = true
    //         }
    //       })
    //       .catch(err => {
    //         console.log(err)
    //       })
    //   }
    // },

    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },

    setCellClass({ rowIndex }) {
      const isHighlighted = this.highlightedCell && this.highlightedCell.rowIndex === rowIndex
      return isHighlighted ? ['highlight'] : []
    },

    closeDialog() {
      this.highlightedCell = ''
      this.costDialog_show = false
    },

    onPerformanceChange(row, item) {
      // 非空过滤
      if (item.performanceValue.trim() === '') {
        item.performanceValue = '0'
      }
      // 直接对商务负责人绩效进行修改
      if (item.ruleCode == 'LEAD_COMMUNICATION') {
        if (Number(item.performanceValue) - Number(row.performanceTotalMoney) >= 0) {
          // 调高了商务负责人的绩效
          this.outMoney = numberToCurrencyNo(
            (Number(item.performanceValue) - Number(row.performanceTotalMoney)).toFixed(2)
          )
          this.passOutShow = true
          this.belowShow = false
        } else if (Number(item.performanceValue) - Number(row.performanceTotalMoney) < 0) {
          // 调低了商务负责人的绩效
          this.outMoney = numberToCurrencyNo(
            (Number(row.performanceTotalMoney) - Number(item.performanceValue)).toFixed(2)
          )
          this.belowShow = true
          this.passOutShow = false
        }
        item.nowRatio = (
          (parseFloat(item.performanceValue) / this.actualPerformance) *
          100
        ).toFixed(2)
        row.nowRatio = item.nowRatio
        row.nowPerformanceTotalMoney = Number(item.performanceValue).toFixed(2)
        row.nowPerformanceTotalMoneyFormat = numberToCurrencyNo(row.nowPerformanceTotalMoney)
        this.refreshTable()
        return
      }

      // 修改当前比例
      let money = parseFloat(item.performanceValue)
      let nowRatio = (money / this.actualPerformance) * 100
      nowRatio = nowRatio.toFixed(2)
      let changeMoney = money - Number(item.nowperformanceValue) //当前用户更改的绩效金额
      item.nowperformanceValue = item.performanceValue
      if (item.contentType == 'MONEY_TYPE') {
        // 计算当前行总绩效占比
        row.lineNowMoney = row.userAssignment.reduce((total, item) => {
          return (total += Number(item.performanceValue))
        }, 0)
        // 计算当前行总绩效占比
        item.nowMoney = item.performanceValue
        row.nowMoney = row.lineNowMoney.toFixed(2)
        row.nowPerformanceTotalMoney = row.lineNowMoney.toFixed(2)
        row.nowPerformanceTotalMoneyFormat = numberToCurrencyNo(
          Number(row.nowPerformanceTotalMoney)
        )
        // 修改信息提供人的额度从公司绩效中扣除
        if (item.ruleCode == 'PROVIDE_INFORMATION' && item.contentType == 'MONEY_TYPE') {
          let moneyTotal = row.userAssignment.reduce(
            (total, item) => (total += Number(item.userMoney)),
            0
          )
          const other = this.tableData[this.Index].find(item => item.ruleCode == 'OTHER')
          let num = (
            Number(other.performanceTotalMoney) -
            Number(row.lineNowMoney) +
            moneyTotal
          ).toFixed(2)
          if (num < 0) {
            item.performanceValue = item.userMoney
            item.nowMoney = item.userMoney
            row.nowMoney = null
            row.nowPerformanceTotalMoney = null
            row.nowPerformanceTotalMoneyFormat = null
            this.refreshTable()
            return this.$message('信息提供人绩效不可超过公司绩效')
          }
          item.nowMoney = item.performanceValue
          row.nowPerformanceTotalMoney = row.lineNowMoney
          row.nowPerformanceTotalMoneyFormat = numberToCurrencyNo(row.lineNowMoney)
          row.nowMoney = row.lineNowMoney
          other.nowPerformanceTotalMoney = num
          other.nowPerformanceTotalMoneyFormat = numberToCurrencyNo(other.nowPerformanceTotalMoney)
          other.nowRatio = (
            (other.nowPerformanceTotalMoney / this.actualPerformance) *
            100
          ).toFixed(2)
          this.refreshTable()
          return
        }
        this.handleLeadMoney(changeMoney, changeMoney, 'MONEY_TYPE')
      } else if (item.contentType == 'RATIO_TYPE') {
        if (nowRatio == 0) nowRatio = 0
        // 计算当前比例与原比例差值
        let changeRatio = Number(nowRatio) - Number(item.nowRatio)
        item.nowRatio = nowRatio
        // 计算当前行总绩效占比
        row.nowRatio = row.userAssignment.reduce(
          (total, item) => (total += Number(item.nowRatio)),
          0
        )
        row.nowRatio = row.nowRatio.toFixed(2)
        // 计算当前行实际绩效总额
        row.nowPerformanceTotalMoney = row.userAssignment
          .reduce((total, item) => {
            return (total += Number(item.performanceValue))
          }, 0)
          .toFixed(2)
        row.nowPerformanceTotalMoneyFormat = numberToCurrencyNo(row.nowPerformanceTotalMoney)
        this.handleLeadMoney(changeMoney, changeRatio, 'RATIO_TYPE')
      }
      this.refreshTable()
    },

    // 处理从负责人头上匀钱操作
    handleLeadMoney(changeMoney, changeRatio, changeType) {
      let num = 0
      if (this.isLeadMoneyChange) {
        if (this.passOutShow && !this.belowShow) {
          // 说明当前绩效超出
          if (Number(changeMoney) > 0) {
            // 已经超了那就继续加到超出金额中
            num = Number(delcommafy(this.outMoney)) + Number(changeMoney)
            this.outMoney = numberToCurrencyNo(num.toFixed(2))
          } else {
            // 如果是个负数说明别的地方减了，那就要判断是否超过超出金额
            if (Number(delcommafy(this.outMoney)) + Number(changeMoney) > 0) {
              // 说明还不够还是有超出
              num = Number(delcommafy(this.outMoney)) + Number(changeMoney)
              this.outMoney = numberToCurrencyNo(num.toFixed(2))
            } else {
              // 说明超出部分已经全部冲抵，剩余可分配
              num = Number(delcommafy(this.outMoney)) + Number(changeMoney)
              let arr = num.toFixed(2).split('-')
              this.outMoney = numberToCurrencyNo(Number(arr[1]))
              this.belowShow = true
              this.passOutShow = false
              return
            }
          }
        } else if (!this.passOutShow && this.belowShow) {
          // 说明当前绩效还可以分配
          if (Number(delcommafy(this.outMoney)) > Number(changeMoney)) {
            // 说明额度够分配，从额度里扣减
            num = Number(delcommafy(this.outMoney)) - Number(changeMoney)
            this.outMoney = numberToCurrencyNo(num.toFixed(2))
          } else {
            // 额度不够了，先扣减全部额度
            // this.belowShow = false
            // this.passOutShow = true
            // num = (Number(changeMoney) - Number(delcommafy(this.outMoney))).toFixed(2)
            // this.outMoney = numberToCurrencyNo(num)
            // return
            this.belowShow = false
            this.passOutShow = false
            num = (Number(changeMoney) - Number(delcommafy(this.outMoney))).toFixed(2)
            // 从商务负责人内容里面匀
            const lead = this.tableData[this.Index].find(
              item => item.ruleCode == 'LEAD_COMMUNICATION'
            )
            if (lead) {
              lead.userAssignment[0].performanceValue = (
                lead.userAssignment[0].performanceValue - num
              ).toFixed(2)
              lead.userAssignment[0].nowRatio = (
                (Number(lead.userAssignment[0].performanceValue) / this.actualPerformance) *
                100
              ).toFixed(2)
              lead.nowRatio = lead.userAssignment[0].nowRatio
              lead.nowPerformanceTotalMoney = lead.userAssignment[0].performanceValue
              lead.nowPerformanceTotalMoneyFormat = numberToCurrencyNo(
                lead.nowPerformanceTotalMoney
              )
            }
            this.outMoney = 0
          }
        }
      } else {
        this.passOutShow = false
        this.belowShow = false
        // 从商务负责人内容里面匀
        const lead = this.tableData[this.Index].find(item => item.ruleCode == 'LEAD_COMMUNICATION')
        if (lead) {
          if (changeType == 'RATIO_TYPE') {
            lead.userAssignment[0].nowRatio = (
              Number(lead.userAssignment[0].nowRatio) - changeRatio
            ).toFixed(2)
            lead.userAssignment[0].performanceValue = (
              Number(lead.userAssignment[0].nowRatio) *
              this.actualPerformance *
              0.01
            ).toFixed(2)
            lead.nowRatio = lead.userAssignment[0].nowRatio
            lead.nowPerformanceTotalMoney = (
              Number(lead.nowRatio) *
              this.actualPerformance *
              0.01
            ).toFixed(2)
            lead.nowPerformanceTotalMoneyFormat = numberToCurrencyNo(lead.nowPerformanceTotalMoney)
          } else if (changeType == 'MONEY_TYPE') {
            lead.userAssignment[0].performanceValue = (
              Number(lead.userAssignment[0].performanceValue) - changeRatio
            ).toFixed(2)
            lead.userAssignment[0].nowRatio = (
              (Number(lead.userAssignment[0].performanceValue) / this.actualPerformance) *
              100
            ).toFixed(2)
            lead.nowRatio = lead.userAssignment[0].nowRatio
            lead.nowPerformanceTotalMoney = (
              Number(lead.nowRatio) *
              this.actualPerformance *
              0.01
            ).toFixed(2)
            lead.nowPerformanceTotalMoneyFormat = numberToCurrencyNo(lead.nowPerformanceTotalMoney)
          }
        }
      }

      const lead = this.tableData[this.Index].find(item => item.ruleCode == 'LEAD_COMMUNICATION')
      if (lead) {
        if (lead.userAssignment[0].performanceValue < 0) {
          let num = lead.userAssignment[0].performanceValue
          const arr = num.split('-')
          this.outMoney = Number(arr[1])
          this.passOutShow = true
          this.belowShow = false
        }
      }
    },

    refreshTable() {
      this.ratioKey++
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.userProportion {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  margin-bottom: 5px;
  .content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    .ratio {
      flex: 1;
      display: flex;
      justify-content: space-around;
    }
  }
}

/deep/.my-label {
  width: 20%;
}
/deep/.my-content {
  width: 20%;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff;
  border-color: #e4e7ed;
  color: #000;
}

/deep/ .el-table th {
  background: #ddeeff;
}
/deep/ .undefined {
  border-bottom: 0px;
}

/deep/.el-table__cell {
  border-left: 1px solid #ebeef5 !important;
}

.below-content {
  display: flex;
  justify-content: space-between;
}
/deep/.height-line {
  color: red;
  font-weight: 700;
}

.title-with-button {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/deep/.el-descriptions__title {
  width: 100% !important;
}
.info-title-with-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cost-dialog-title {
  margin-bottom: 10px;
}

/deep/.businessOutSource {
  //费用类别背景色
  background-color: #b2f3fe;
  border: 1px solid #ddd !important;
}

.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

.empty-detail {
  height: 444px;
  color: grey;
  font-size: 14px;
  text-align: center;
  position: relative;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

::v-deep .highlight {
  background: rgb(241, 212, 158) !important;
}

/deep/.el-table tbody tr:hover > td {
  background-color: transparent;
}
/deep/.projectBGC {
  //背景色
  background-color: #d0f6fd !important;
  border: 1px solid #ddd !important;
}
</style>
