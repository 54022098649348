import { render, staticRenderFns } from "./BusinessComputation.vue?vue&type=template&id=1dd73fae&scoped=true&"
import script from "./BusinessComputation.vue?vue&type=script&lang=js&"
export * from "./BusinessComputation.vue?vue&type=script&lang=js&"
import style0 from "./BusinessComputation.vue?vue&type=style&index=0&id=1dd73fae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dd73fae",
  null
  
)

export default component.exports